import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, redirect, Route } from 'react-router';
import { OopsPageError } from '#screens/errors/OopsPageError';
import { Devtools } from './components/patterns/devtools/Devtools';
import { NavLayout } from './components/patterns/layouts/nav/NavLayout';
import { Authenticated } from './components/patterns/routing/Authenticated';
import { FeatureFlagged } from './components/patterns/routing/FeatureFlagged';
import { GotStarted } from './components/patterns/routing/GotStarted';
import { useGlobalState } from './global/context/global-state/useGlobalState';
import { NotFound } from './screens/404/NotFound';
import { authRootRoute } from './screens/auth/AuthRoot';
import { Oops } from './screens/errors/Oops';
import { hostsRootRoute } from './screens/hosts/HostsRoot';
import { lighthousesRootRoute } from './screens/lighthouses/LighthousesRoot';
import { LoadingAuthPage } from './screens/loading/LoadingAuthPage.jsx';
import { logsRootRoute } from './screens/logs/LogsRoot';
import { relaysRootRoute } from './screens/relays/RelaysRoot';
import { rolesRootRoute } from './screens/roles/RolesRoot';
import { routesRootRoute } from './screens/routes/RoutesRoot';
import { settingsRootRoute } from './screens/settings/SettingsRoot';
import { tagsRootRoute } from './screens/tags/TagsRoot';
import { Theme } from './Theme';
import { Titled } from './util/title';

function AppComponent() {
  return (
    <Titled title={() => 'Defined Networking'}>
      <Theme />
      <Outlet />
      <Devtools />
      <div id="scrollbar-test" />
    </Titled>
  );
}

const App = Sentry.withProfiler(AppComponent);

function NavLayoutWrapper() {
  const { setGlobalError } = useGlobalState();

  return (
    <Suspense>
      <NavLayout>
        <ErrorBoundary
          // Don't want to show `error` w/o a filter
          fallbackRender={(props) => <Oops {...props} showErrorDetails={false} />}
          onError={(error) => {
            // Force links to be full page loads, to reset the error boundary
            setGlobalError(error);
          }}
        >
          <Outlet />
        </ErrorBoundary>
      </NavLayout>
    </Suspense>
  );
}

export const appRoute = (
  <Route element={<App />} errorElement={<OopsPageError />}>
    <Route path="/auth">{authRootRoute}</Route>
    <Route
      path="/signup"
      lazy={() => import('#route-bundles/unauthenticated').then(({ SignupPage }) => ({ Component: SignupPage }))}
    />
    <Route
      path="/deleted-account"
      lazy={() =>
        import('#route-bundles/unauthenticated').then(({ DeletedAccountPage }) => ({
          Component: DeletedAccountPage,
        }))
      }
    />
    <Route
      element={
        <Authenticated>
          <Outlet />
        </Authenticated>
      }
    >
      <Route
        path="/signup/pro"
        element={
          <Suspense fallback={<LoadingAuthPage />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route
          index={true}
          // Lazy load this so that we don't pollute every page with calls to stripe
          lazy={() =>
            import('#screens/signup/pro/SignupCheckoutPage').then(({ SignupCheckoutPage }) => ({
              Component: SignupCheckoutPage,
            }))
          }
        />
      </Route>
      <Route
        path="/get-started"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ GetStartedPage }) => ({
            element: (
              <Suspense>
                <GetStartedPage />
              </Suspense>
            ),
          }))
        }
      />
      <Route element={<NavLayoutWrapper />}>
        <Route
          element={
            <GotStarted>
              <Outlet />
            </GotStarted>
          }
        >
          <Route
            index={true}
            loader={() => redirect('/hosts')}
            element={<Navigate to={{ pathname: '/hosts' }} replace={true} />}
          />
          <Route path="/hosts">{hostsRootRoute}</Route>
          <Route path="/lighthouses">{lighthousesRootRoute}</Route>
          <Route path="/relays">{relaysRootRoute}</Route>
          <Route path="/roles">{rolesRootRoute}</Route>
          <Route path="/settings">{settingsRootRoute}</Route>
          <Route
            path="/support/contact"
            lazy={() =>
              import('#route-bundles/authenticated').then(({ ContactUsPage }) => ({
                Component: ContactUsPage,
              }))
            }
          />
          <Route path="/tags">{tagsRootRoute}</Route>
          <Route path="/routes">{routesRootRoute}</Route>
          <Route
            path="/downloads"
            lazy={() =>
              import('#route-bundles/authenticated').then(({ DownloadsRoot }) => ({
                Component: DownloadsRoot,
              }))
            }
          />
        </Route>
        <Route
          path="/certificate-authorities"
          lazy={() =>
            import('#route-bundles/authenticated').then(({ CertificateAuthoritiesRoot }) => ({
              element: (
                <FeatureFlagged featureFlagIdent="LIST_CERT_AUTHORITIES">
                  <CertificateAuthoritiesRoot />
                </FeatureFlagged>
              ),
            }))
          }
        />
        <Route path="/logs">{logsRootRoute}</Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  </Route>
);
