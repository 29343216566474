import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, Route } from 'react-router';
import { NotFound } from '#screens/404/NotFound';
import { Oops } from '#screens/errors/Oops';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';
import { fromArray, page, Titled } from '#util/title';

/**
 * {@link logsRootRoute} contains all of the routing config for the /logs path
 */
export const logsRootRoute = (
  <Route
    element={
      <Titled title={() => page('Audit logs')}>
        <Outlet />
      </Titled>
    }
  >
    {['audit', 'audit/export'].map((path) => (
      <Route
        key={path}
        path={path}
        lazy={() =>
          import('#route-bundles/authenticated').then(({ AuditLogsPage }) => ({
            element: (
              <Suspense fallback={<LoadingSpinner />}>
                <ErrorBoundary fallbackRender={(props) => <Oops {...props} showErrorDetails={false} />}>
                  <AuditLogsPage />
                </ErrorBoundary>
              </Suspense>
            ),
          }))
        }
      >
        <Route
          path="export"
          lazy={() =>
            import('#route-bundles/authenticated').then(({ ExportLogsModal }) => ({
              element: (
                <Titled title={(title) => fromArray(['Export', title])}>
                  <ExportLogsModal />
                </Titled>
              ),
            }))
          }
        />
      </Route>
    ))}
    <Route
      index={true}
      element={
        /* We only have /logs/audit right now, so redirect /logs there, for convenience */
        <Navigate to="/logs/audit/" replace={true} />
      }
    />
    <Route path="*" element={<NotFound />} />
  </Route>
);
