import { CopyIcon } from '#assets/ReactSvg';
import { IconButton } from './icon-button/IconButton';
import type { IconButtonProps } from './types';

export function CopyIconButton({ size, title = 'Copy', ...buttonProps }: IconButtonProps) {
  return (
    <IconButton title={title} height={size} width={size} {...buttonProps}>
      <CopyIcon />
    </IconButton>
  );
}
