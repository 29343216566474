import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, Route } from 'react-router';
import { fallbackRenderNotFound } from '#screens/errors/fallbackRenderNotFound';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';
import { fromArray, Titled } from '#util/title';

/**
 * {@link networksRootRoute} contains all of the routing config for the /networks path
 */
export const networksRootRoute = (
  <Route
    element={
      <Titled title={(title) => fromArray(['Networks', title])}>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Titled>
    }
  >
    <Route
      index={true}
      lazy={() => import('#route-bundles/authenticated').then(({ NetworksPage }) => ({ Component: NetworksPage }))}
    />
    <Route
      path="add"
      lazy={() => import('#route-bundles/authenticated').then(({ AddNetworkPage }) => ({ Component: AddNetworkPage }))}
    />
    <Route
      path=":networkId"
      element={
        <ErrorBoundary fallbackRender={fallbackRenderNotFound}>
          <Outlet />
        </ErrorBoundary>
      }
    >
      <Route
        index={true}
        lazy={() =>
          import('#route-bundles/authenticated').then(({ EditNetworkPage }) => ({ Component: EditNetworkPage }))
        }
      />
    </Route>
  </Route>
);
