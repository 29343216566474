import { NotificationsContext } from './NotificationsContext';
import { useNotificationsProvider, type InitialState } from './useNotificationsProvider';

type Props = {
  children: React.ReactNode;
  /** So far, initial state is only used for testing, but could be useful for 'pre-seeding' initial global state on app startup */
  initialState?: InitialState;
};

/**
 * {@link NotificationsProvider} is a context provider component to make useNotifications() available to our app.
 */
export function NotificationsProvider({ children, initialState }: Props) {
  const state = useNotificationsProvider(initialState);
  return <NotificationsContext.Provider value={state}>{children}</NotificationsContext.Provider>;
}
