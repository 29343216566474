import { default as ArrowRightIcon } from '#assets/arrow-right.svg?react';
import { IconButton } from './icon-button/IconButton';
import type { IconButtonProps } from './types';

export function ArrowRightButton({ size, title = 'Arrow right', ...buttonProps }: IconButtonProps) {
  return (
    <IconButton title={title} height={size} width={size} {...buttonProps}>
      <ArrowRightIcon />
    </IconButton>
  );
}
