import cn from 'classnames';
import styles from './Button.module.css';

type Props = React.ComponentPropsWithRef<'button'> & {
  /** The type of button this should be.  Note, the icon variant is different from an icon button, and is similar to a secondary button with different padding and color */
  variant?: 'primary' | 'secondary' | 'danger' | 'link' | 'linkPrimary' | 'icon';
  /** Size controls the padding, height, and font-size */
  size?: 'xsmall' | 'small' | 'medium';
  /** Optional classes to apply to the button */
  className?: string;
};

export function Button({ variant = 'primary', children, size = 'medium', className, ...htmlProps }: Props) {
  const classes = cn(
    'btn',
    styles.Button,
    styles[`Button___${variant}` as const],
    styles[`Button___${size}` as const],
    className
  );

  return (
    <button className={classes} type="button" {...htmlProps}>
      {children}
    </button>
  );
}
