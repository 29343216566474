import { Suspense } from 'react';
import { Outlet, Route } from 'react-router';
import { NotFound } from '#screens/404/NotFound';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';
import { IsMFAUser } from '#screens/settings/IsMFAUser.jsx';
import { fromArray, page, Titled } from '#util/title';

export const billingRootRoute = (
  <Route
    element={
      <Titled title={(title) => fromArray(['Billing', title])}>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Titled>
    }
  >
    <Route
      lazy={() =>
        import('#route-bundles/authenticated').then(({ BillingPage }) => ({
          element: (
            <>
              <BillingPage />
              <Outlet />
            </>
          ),
        }))
      }
    >
      <Route index={true} /**  We just want to mark this as the index route, render the above layout routes. */ />
      <Route
        path="upgrade-success"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ UpgradeSuccessModal }) => ({
            element: (
              <Titled title={() => page('Upgrade succeeded')}>
                <UpgradeSuccessModal />
              </Titled>
            ),
          }))
        }
      />
    </Route>
    <Route
      element={
        <IsMFAUser redirectTo={'/settings/billing'}>
          <Outlet />
        </IsMFAUser>
      }
    >
      <Route
        path="checkout"
        // Lazy load the checkout page to avoid Stripe unless necessary.
        lazy={() =>
          import('./checkout/CheckoutPage.jsx').then(({ CheckoutPageWithStripe }) => ({
            Component: CheckoutPageWithStripe,
          }))
        }
      />
      <Route
        path="upgrade-pending"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ PendingUpgradePage }) => ({
            Component: PendingUpgradePage,
          }))
        }
      />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Route>
);
