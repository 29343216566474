import { CloseIcon } from '#assets/ReactSvg';
import { IconButton } from './icon-button/IconButton';
import type { IconButtonProps } from './types';

export function CloseButton({ size, title = 'Close', ...buttonProps }: IconButtonProps) {
  return (
    <IconButton title={title} height={size} width={size} {...buttonProps}>
      <CloseIcon />
    </IconButton>
  );
}
