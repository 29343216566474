import cn from 'classnames';
import { cloneElement } from 'react';
import styles from './IconButton.module.css';

type Props = React.ComponentPropsWithRef<'button'> & {
  /** Width of the SVG */
  width?: number | string;
  /** Height of the SVG */
  height?: number | string;
  /** Optional additional class names to apply to the button element */
  className?: string;
  /** Accessible button name for screen readers */
  title: string;
  /** Reactified svg component */
  children: React.ReactElement<React.ComponentProps<'svg'> & { title?: string }>;
};

/**
 * {@link IconButton} can be used to create a button out of an svg icon, along with an accessible title
 * which is not displayed on the screen.
 */
export function IconButton({ width = 16, height = 16, className, title, children, ...buttonProps }: Props) {
  const svgChild = cloneElement(children, { width, height, 'aria-hidden': true });

  return (
    <button className={cn('btn icon-btn pad-8', className)} type="button" {...buttonProps}>
      {svgChild}
      <span className={cn('visually-hidden', styles.IconButton_visuallyHiddenText)}>{title}</span>
    </button>
  );
}
