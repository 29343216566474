import cn from 'classnames';
import { NavLink as ReactRouterNavLink, type NavLinkProps } from 'react-router';
import { useGlobalState } from '#global/context/global-state/useGlobalState';

type Props = Omit<NavLinkProps, 'className'> & {
  className?: string;
  activeClassName?: string;
};

/**
 * Wraps the {@link ReactRouterNavLink} component from react-router: https://reactrouter.com/en/main/components/nav-link
 *
 * The difference is that our version will force a full page load if {@link useGlobalState} wants one
 */
export function NavLink({ className, activeClassName, reloadDocument, ...props }: Props) {
  const { fullPageloadNeeded } = useGlobalState();

  return (
    <ReactRouterNavLink
      className={({ isActive }) => cn(className, { [activeClassName ?? '']: isActive })}
      reloadDocument={fullPageloadNeeded || reloadDocument}
      {...props}
    />
  );
}
