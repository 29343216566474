import { Navigate } from 'react-router';
import { useAppLocation } from '#hooks/useAppLocation';
import { NotFound } from '#screens/404/NotFound';

type Props = {
  children: React.ReactNode;
  isPermitted: () => boolean;
  redirectTo?: string | (() => string);
};

/**
 * A component that can be used inside a Route to run an 'isPermitted' callback, and if that returns false either:
 *
 * 1) show a 404 page
 * 2) if a `redirectTo` is provided, redirect to that path.
 */
export function Protected({ children, isPermitted, redirectTo }: Props) {
  const location = useAppLocation();

  return (
    isPermitted() ? <>{children}</>
    : redirectTo ?
      <Navigate
        to={typeof redirectTo === 'function' ? redirectTo() : redirectTo}
        state={{ from: location }}
        replace={true}
      />
    : <NotFound />
  );
}
