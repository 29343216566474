import { default as ArrowLeftIcon } from '#assets/arrow-left.svg?react';
import { IconButton } from './icon-button/IconButton';
import type { IconButtonProps } from './types';

export function ArrowLeftButton({ size, title = 'Arrow left', ...buttonProps }: IconButtonProps) {
  return (
    <IconButton title={title} height={size} width={size} {...buttonProps}>
      <ArrowLeftIcon />
    </IconButton>
  );
}
