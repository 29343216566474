import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, Route } from 'react-router';
import { fallbackRenderNotFound } from '#screens/errors/fallbackRenderNotFound';
import { LoadingSpinner } from '#screens/loading/LoadingSpinner';
import { page, Titled } from '#util/title';

/**
 * {@link hostsRootRoute} contains all of the routing config for the /hosts path
 */
export const hostsRootRoute = (
  <Route
    element={
      <Titled title={() => page('Hosts')}>
        <Suspense fallback={<LoadingSpinner />}>
          <Outlet />
        </Suspense>
      </Titled>
    }
  >
    <Route
      index={true}
      lazy={() => import('#route-bundles/authenticated').then(({ HostsPage }) => ({ Component: HostsPage }))}
    />
    <Route
      path="add"
      lazy={() => import('#route-bundles/authenticated').then(({ AddHostPage }) => ({ Component: AddHostPage }))}
    />
    <Route
      path=":hostId"
      element={
        <ErrorBoundary fallbackRender={fallbackRenderNotFound}>
          <Outlet />
        </ErrorBoundary>
      }
    >
      <Route
        index={true}
        lazy={() =>
          import('#route-bundles/authenticated').then(({ HostDetailPage }) => ({
            element: (
              <Titled title={() => page('Host')}>
                <HostDetailPage />
              </Titled>
            ),
          }))
        }
      />
      <Route
        path="edit"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ EditHostPage }) => ({
            element: <EditHostPage />,
          }))
        }
      />
      <Route
        path="unblock"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ EnrollPage }) => ({
            element: (
              <Titled title={() => page('Unblock host')}>
                <EnrollPage verb="unblock" />
              </Titled>
            ),
          }))
        }
      />
      <Route
        path="enroll"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ EnrollPage }) => ({
            element: (
              <Titled title={() => page('Enroll host')}>
                <EnrollPage />
              </Titled>
            ),
          }))
        }
      />
      <Route
        path="debug"
        lazy={() =>
          import('#route-bundles/authenticated').then(({ DebugPage }) => ({
            element: <DebugPage />,
          }))
        }
      />
    </Route>
  </Route>
);
