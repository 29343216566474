import { useEffect } from 'react';
import { useRouteError } from 'react-router';
import { trackError } from '#api/errors';
import { OopsPage } from '#screens/errors/OopsPage';

/** This component is meant to provide an https://reactrouter.com/en/main/route/error-element
 * that tracks the thrown error before rendering <OopsPage/> without showing the error.
 */
export function OopsPageError() {
  const error = useRouteError();

  useEffect(() => {
    trackError(error);
  }, [error]);

  return <OopsPage error={error} showErrorDetails={!__IS_PROD__} />;
}
