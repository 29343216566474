import { useQueryClient } from '@tanstack/react-query';
import { useLayoutEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { AuthenticationError, MissingResourceError, trackError } from '#api/errors';
import { useAuth } from '#global/context/auth/useAuth';
import { Protected } from './Protected';

type Props = {
  children: React.ReactNode;
};

/**
 * A component to use inside a <Route> that redirects to the login screen if you're not yet authenticated.
 */
export function Authenticated({ children }: Props) {
  const isSignupProCheckout = useMatch('/signup/pro');
  const { clearAuth, signout, getAuthState, oidcToken, redirectToOIDC, oidcLoginURL } = useAuth();
  const [initialized, setInitialized] = useState<boolean>(false);
  const queryClient = useQueryClient();

  // If we get a 401 response when making a request on one of these pages, clear the cache and sign out
  useLayoutEffect(() => {
    // We only need to set up the retry handler once.
    if (!initialized) {
      const defaultQueryOptions = queryClient.getDefaultOptions();
      queryClient.setDefaultOptions({
        ...defaultQueryOptions,
        queries: {
          ...defaultQueryOptions.queries,
          // If we detect an AuthenticationError, don't retry, and instead log out
          retry: (failureCount, error) => {
            if (error instanceof AuthenticationError) {
              // Clearing auth does not reset the redirectToOIDC state nor the user token,
              // which lets us show the correct login page
              // Note: oidcToken will be a stale reference to whatever existed when the queryClient is initialized
              if (oidcToken || error.code === 'ERR_2FA_REQUIRED') {
                clearAuth();
                // Cancel any queries that are still in flight, we don't want to trigger multiple calls to clearAuth.
                // The fetches will still happen unless they consume the abort signal from react-query, but they won't be handled by react-query.
                void queryClient.cancelQueries();
                return false;
              }
              if (error.code === 'ERR_UNAUTHORIZED') {
                void signout();
                return false;
              }
              // error.code === 'ERR_2FA_RENEWAL_REQUIRED'
              // Pages that expect this error should handle it explicitly, it should not bubble up to here.
              trackError('Unhandled ERR_2FA_RENEWAL_REQUIRED');
              return false;
            }
            // No need to retry a 404
            if (error instanceof MissingResourceError) {
              return false;
            }

            // Do not retry fetches in storybook, it just slows things down and screws up snapshots
            if (import.meta.env.STORYBOOK) {
              return false;
            }

            return failureCount < 3;
          },
        },
      });
      setInitialized(true);
    }
  }, [oidcToken, clearAuth, initialized, queryClient, signout]);

  const authState = getAuthState();

  // Wait until we have had a chance to set up the retry method
  if (!initialized) return null;

  return (
    <Protected
      isPermitted={() => authState === 'authenticated'}
      redirectTo={() => {
        if (authState === 'unauthenticated' && isSignupProCheckout) {
          // Redirect to the signup page if you loaded the signup checkout page without authenticating.
          return '/signup?plan=pro';
        }
        if (authState === 'mfaRequired') {
          return '/auth/mfa';
        }
        if (typeof oidcLoginURL === 'string' && redirectToOIDC) {
          return oidcLoginURL;
        }
        return '/auth/login';
      }}
    >
      {children}
    </Protected>
  );
}
