// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- This is the wrapped component that we should be using instead
import { Link as ReactRouterLink, type LinkProps } from 'react-router';
import { useGlobalState } from '#global/context/global-state/useGlobalState';

type Props = LinkProps & { ref?: React.Ref<HTMLAnchorElement> };

/**
 * Wraps the `Link` component from react-router: https://reactrouter.com/en/main/components/link
 *
 * The difference is that our version will turn into a real anchor tag to force a full page load if a new
 * version of the webapp is available.
 */
export function Link({ to, children, replace, reloadDocument, ref, ...props }: Props) {
  const { fullPageloadNeeded } = useGlobalState();

  return (
    <ReactRouterLink
      ref={ref}
      to={to}
      replace={replace}
      reloadDocument={fullPageloadNeeded || reloadDocument}
      {...props}
    >
      {children}
    </ReactRouterLink>
  );
}
