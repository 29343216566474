import { queryOptions, usePrefetchQuery, useSuspenseQuery } from '@tanstack/react-query';
import { Org, orgQueryKeys } from '#api';

/** {@link featureFlagQueryOptions} fetches feature flags from the api. */
export const featureFlagQueryOptions = queryOptions({
  queryKey: orgQueryKeys.featureFlags(),
  queryFn: ({ signal }) => Org.getFeatureFlags({ signal }),
  // 10 minutes
  staleTime: 600000,
});

export function usePrefetchFeatureFlags() {
  usePrefetchQuery(featureFlagQueryOptions);
}

/**
 * {@link useSuspenseFeatureFlagsQuery} fetches feature flags from the api.
 */
export function useSuspenseFeatureFlagsQuery() {
  return useSuspenseQuery(featureFlagQueryOptions);
}
