import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { assert, configureAssert } from 'assert-ts';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import { RouterProvider } from 'react-router/dom';
import { trackError, trackUnrewordedValidationError, ValidationError } from './api/errors';
import { appRoute } from './App';
import { ContextProviders } from './ContextProviders';

const environment = import.meta.env.MODE;

/**
 * {@link filterOutSensitiveParams} removes the `token` search parameter, and
 * all parameters from /auth/oidc-callback from the request url
 * that is sent to sentry.io.
 */
function filterOutSensitiveParams<T extends Sentry.Event>(event: T) {
  // Remove token param before sending (e.g. from /auth/magic-link)
  if (event.request?.url?.includes('token')) {
    const url = new URL(event.request.url);
    const params = new URLSearchParams(url.search);
    params.delete('token');
    url.search = params.toString();
    event.request.url = url.toString();
  }

  // Remove all query params from /oidc-callback
  if (event.request?.url?.includes('/auth/oidc-callback')) {
    const url = new URL(event.request.url);
    url.search = '';
    event.request.url = url.toString();
  }
  return event;
}

if (environment === 'production' || environment === 'staging') {
  const integrations = [
    // eslint-disable-next-line import/namespace
    Sentry.browserProfilingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      enableInp: true,
    }),
    // eslint-disable-next-line import/namespace -- dunno why this is type-erroring
    Sentry.thirdPartyErrorFilterIntegration({
      // Specify the application keys that you specified in the Sentry bundler plugin
      filterKeys: ['dn-webclient'],
      // Defines how to handle errors that contain third party stack frames.
      behaviour: 'apply-tag-if-contains-third-party-frames',
    }),
    // eslint-disable-next-line import/namespace
    Sentry.extraErrorDataIntegration(),
  ];

  if (environment === 'staging') {
    integrations.push(
      // Only enable Session Replay on staging deploys to test.
      // eslint-disable-next-line import/namespace
      Sentry.replayIntegration({
        // TODO [@sentry/react@>=9]: Update this script
        // <https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay/configuration/#using-a-custom-compression-worker>
        workerUrl: '/scripts/sentry-replay-worker.min.js',
      })
    );
  }

  Sentry.init({
    environment: __SENTRY_ENVIRONMENT__,
    dsn: 'https://167af0ae6cd24240b61dc0dc4a6598f3@o920269.ingest.sentry.io/5865647',
    tunnel: '/sentrytunnel',
    integrations,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://admin.defined.net', 'https://admin.undefined.works'],

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    beforeSend(event) {
      return filterOutSensitiveParams(event);
    },

    // Sentry recommends 0.1 (10%) for production and 1 (100%) for staging.
    // Currently this is set to 0 so that we spend our 50 free session replays on actual errors.
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });

  // Report failures of `assert.soft` to Sentry when in prod.
  configureAssert({
    warningReporter(_failureType, message, props) {
      trackError(new Error(message, { cause: props }));
    },
  });
}

// Prevent number inputs from changing due to scroll wheel
document.addEventListener('wheel', () => {
  if (document.activeElement instanceof HTMLInputElement && document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});

if (environment !== 'test') {
  const container = assert(document.getElementById('root'));
  const root = createRoot(container);

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);
  const router = sentryCreateBrowserRouter(createRoutesFromChildren(appRoute));

  const queryCache = new QueryCache({
    onError(error) {
      if (error instanceof ValidationError && !error.reported) {
        // Only track validation messages that are unexpected and haven't already been reported
        trackUnrewordedValidationError(error, trackError);
      } else {
        trackError(error);
      }
    },
  });

  const loadMocks = () =>
    import('#mock/server.browser.js')
      .then(async ({ serverConfig, makeServer }) => {
        const newServerConfig = { ...serverConfig, environment };
        await makeServer(newServerConfig);
        return;
      })
      .catch((err: unknown) => {
        console.error('Could not start up mock server:', err);
      });

  // Create a mock server for our api, if needed
  if (__IS_MOCKED__) {
    await loadMocks();
  }

  root.render(
    <StrictMode>
      <ContextProviders
        client={
          new QueryClient({
            queryCache,
            defaultOptions: { queries: { networkMode: __IS_MOCKED__ ? 'always' : 'online' } },
          })
        }
      >
        <RouterProvider router={router} />
      </ContextProviders>
    </StrictMode>
  );
}
